import { SolidgearProduct } from '@hultafors/solidgear/types';

export const generateFakeIdForProduct = (product: SolidgearProduct): string => {
  // if (product && product.size) {
  //   fakeSKU += `-${product.size.value}`;
  // }
  return product.sku;
};

export const addToCart = (
  cart: SolidgearProduct[],
  product: SolidgearProduct,
  amount = 1,
) => {
  let result: SolidgearProduct[] = [];

  if (!product) {
    return cart ? [...cart] : result;
  }

  const fakeSKU = generateFakeIdForProduct(product);
  amount = isNaN(amount) ? 1 : amount;
  const cartProduct: SolidgearProduct = {
    ...product,
    fakeSKU,
    amount,
  };

  if (cart) {
    const existingItem = cart.find((x) => x.fakeSKU === product.fakeSKU);

    if (existingItem) {
      const otherItems = cart.filter((x) => x.fakeSKU !== product.fakeSKU);
      // add one amount to existing cart item
      cartProduct.amount = existingItem.amount
        ? existingItem.amount + amount
        : amount;
      result = [...otherItems, cartProduct];
    } else {
      result = [...cart, cartProduct];
    }
  } else {
    // Cart does not exist, create with new product
    result = [cartProduct];
  }

  return result;
};
